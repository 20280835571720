.login-wrapper {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.login-content {
  display: flex;
  align-items: center;
  gap: 5rem;
}

.form {
  padding: 25px 40px 60px;
  /* background-color: #36A1FB; */
  width: 300px;
  background-color: #36a2fb12;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 15px 12px 25px 0px #80808041;
}

.sign-in-info {
  font-size: 0.9rem;
  text-align: left;
  font-weight: 100;
}

.label-input {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: 30px;
  width: inherit;
}

.form-label {
  font-size: 0.9rem;
}

.form-input {
  margin-top: 8px;
  padding: 6px 9px;
  border-radius: 5px;
  height: 20px;
  margin-bottom: 25px;
  border: 1px solid grey;
}

.form-btn {
  width: inherit;
  border-radius: 5px;
  background-color: #36a1fb;
  color: white;
  font-weight: 600;
  font-size: 1rem;
  height: 35px;
  border: none;
  margin-top: 14px;
  margin-bottom: 35px;
}

.form-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: inherit;
}

.form-link {
  font-size: 0.8rem;
  color: #36a1fb;
  font-weight: 600;
  text-decoration: none;
}

.form-checkbox {
  font-size: 0.8rem;
}

.login-image {
  height: 500px;
  width: 500px;
}

.one {
  height: 100%;
  width: 320px;
  background-color: #c8def16e;
  position: absolute;
  top: 0;
  z-index: -1;
}

.place1 {
  margin-left: 50px;
}

.place2 {
  margin-left: 390px;
}

.place3 {
  margin-left: 730px;
}

.place4 {
  margin-left: 1070px;
}

.btn {
  height: 38px;
  width: 120px;
  font-size: 1rem;
  font-weight: 600;
  box-shadow: 7px 8px 12px 2px rgb(196 195 195);
  margin: 20px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

@media screen and (max-width: 900px) {
  .login-content {
    gap: 1rem;
  }
  .form {
    width: 200px;
    padding: 15px 30px 50px;
  }
  .login-image {
    height: 400px;
    width: 400px;
  }
}
@media screen and (max-width: 500px) {
  .login-wrapper {
    align-items: baseline;
  }
  .login-content {
    flex-direction: column-reverse;
  }
  .form {
    width: 75vw;
    border-radius: 1rem;
  }

  .login-image {
    height: 200px;
    width: 300px;
  }
}
