body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar {
    display: inline-block;
    width: 100px;
    position: absolute;
    left: 5px;
}

.other-icons {
    position: relative;
    bottom: 75px;
}

.nav-icons {
    height: 35px;
    width: 35px;
    font-size: 0.8rem;
    margin: 62px 35px 62px 35px;
}

.icon {
    height: 35px;
    width: 35px;
    cursor: pointer;
}

.position {
    position: relative;
    bottom: 58px;
    left: 35px;
}
.title {
  color: #36a1fb;
  margin: 0;
}

.titlebar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 50px;
  padding-top: 10px;
}

.note {
  font-size: 14px;
  white-space: nowrap;
  color: red;
  width: 50%;
  margin: 0 auto;
  overflow: hidden;
}

.note span {
  display: inline-block;
  padding-right: 10px;
  animation: floatLeft 20s linear infinite;
}

.note:hover span {
  animation-play-state: paused;
}

.login {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 10px;
  gap: 10px;
}

.sign-in-name {
  font-weight: 600;
}

.popup-container {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  padding: 35px;
  background-color: white;
  border-radius: 10px;
  position: absolute;
  top: 80px;
  right: 50px;
  z-index: 1;
  box-shadow: 7px 13px 22px 2px rgb(196 195 195);
}

.disable-popup {
  display: none;
}
.user-detail {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
}
.pop-icon {
  height: 80px;
  width: 80px;
}
.email {
  margin-left: 50px;
}
.i-holder {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  grid-gap: 10px;
  gap: 10px;
}
.i-label {
  grid-gap: 1rem;
  gap: 1rem;
  display: flex;
  justify-content: space-between;
}
.btn-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}
.popup-btn {
  padding: 10px;
  background-color: #36a1fb;
  border: none;
  border-radius: 10px;
  color: white;
  font-size: 0.8rem;
  font-weight: 600;
  cursor: pointer;
}
.btn-holder {
  display: flex;
  height: max-content;
  grid-gap: 25px;
  gap: 25px;
}
.radio-holder {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  align-self: center;
}
.flex-max {
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.flex-justify {
  display: flex;
  justify-content: space-between;
  grid-gap: 1rem;
  gap: 1rem;
}
.qr-holder {
  display: flex;
  flex-direction: column;
  align-self: center;
  text-align: center;
  font-weight: 600;
  align-items: center;
}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropbtn {
  background-color: gray;
  padding: 10px;
  border: none;
  border-radius: 10px;
  color: white;
  font-size: 0.8rem;
  font-weight: 600;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 45px;
}

.dropdown-content button {
  color: black;
  padding: 10px 15px;
  text-decoration: none;
  display: block;
  cursor: pointer;
  width: 100%;
}

.dropdown-content button:hover {
  background-color: #ddd;
}

.dropdown-content {
  display: block;
}

.selected-option {
  margin-top: 10px;
}

.fastag-popup {
  /* width: fit-content; */
  width: 880px;
  position: absolute;
  right: auto;
}

.tableContainer {
  max-height: 400;
  overflow-wrap: "auto";
}

.flex-justify-fastag {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
}

@keyframes floatLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.modal-content {
  background: #fff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  position: relative;
  display: flex;
  flex-direction: column;
  grid-gap: 25px;
  gap: 25px;
}

.modal-heading {
  display: flex;
  justify-content: space-between;
  margin-top: 0;
  margin-bottom: 0;
  grid-gap: 300px;
  gap: 300px;
}

.close-button {
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}

.modal-body {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.amount-input {
  width: 95%;
  padding: 10px;
  border: 1px solid #000;
  border-radius: 5px;
}

.recharge {
  background-color: #36a1fb;
  border: none;
  border-radius: 10px;
  color: white;
  font-size: 0.8rem;
  font-weight: 600;
  cursor: pointer;
  padding: 10px;
  width: 100%;
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

/* For webkit browsers (Chrome, Safari) */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.inner {
  margin: 0px 10px; /*margin:0px 60px;*/
}

.align-btns {
  display: flex;
  align-items: center;
  grid-gap: 30rem;
  gap: 30rem;
  margin-top: 30px;
  margin-left: 0px; /*margin:80px;*/
  width: 100%;
}

.align-btn {
  width: 70%;
}

.align-count {
  font-weight: 600;
}

.align-counts {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  width: 30%;
}

.report-container {
    /* display: flex;
    flex-direction: column; */
    /* margin: 10px 10px 10px 10px; */
    background-color: inherit;
    /* filter: var(--filter); */
    /* border-radius: var(--border-radius);
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 1); */
    padding: 30px;
    border: black ridge 4px;
    /* q */
}

.report-table {
    border-top: black double 3px;
    border-bottom: black solid 1px;
    background-color: rgba(236, 238, 236, 0.884);
    /* overflow-x: scroll; */
}

#wrapper1, #wrapper2 {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
}

#wrapper1 {
    height: 20px;
}

.div1 {
    /* width: 100px; */
    height: 20px;
}

.div2 {
    /* width: 1000px; */
    /* height: 100px; */
    /* background-color: #88FF88; */
    /* overflow-y: auto; */
    /* border-top: black double 3px;
    border-bottom: black solid 1px; */
    border-top: black double 3px;
    border-bottom: black solid 1px;
    background-color: rgba(236, 238, 236, 0.884);
}

.pagination {
    margin-bottom: 20px;
}


/* responsivess */

@media only screen and (max-width: 636px) {
    .report-container {
        width: 450px;
    }
}

@media only screen and (max-width: 565px) {
    .report-container {
        width: 380px;
    }
}

@media only screen and (max-width: 499px) {
    .report-container {
        width: 350px;
    }
}

@media only screen and (max-width: 470px) {
    .report-container {
        width: 300px;
    }
}

@media only screen and (max-width: 422px) {
    .report-container {
        width: 270px;
    }
}

@media only screen and (max-width: 390px) {
    .report-container {
        width: 230px;
    }
}
.cards {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-rows: auto;
  margin-left: 0px; /*marign-left:100px;*/
  grid-column-gap: 2rem;
  column-gap: 2rem;
}

.card {
  box-shadow: 7px 13px 22px -3px rgb(196 195 195);
  border-radius: 4px;
  padding: 50px;
  cursor: pointer;
}

.cardbordered {
  box-shadow: 7px 13px 22px -3px rgb(196 195 195);
  border-radius: 4px;
  padding: 50px;
  cursor: pointer;
  border: 3px solid #36a1fb;
  border-radius: 7px;
}

.underline {
  text-decoration: none;
  color: black;
  font-size: 1.1rem;
  font-weight: 600;
}

.login-wrapper {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.login-content {
  display: flex;
  align-items: center;
  grid-gap: 5rem;
  gap: 5rem;
}

.form {
  padding: 25px 40px 60px;
  /* background-color: #36A1FB; */
  width: 300px;
  background-color: #36a2fb12;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 15px 12px 25px 0px #80808041;
}

.sign-in-info {
  font-size: 0.9rem;
  text-align: left;
  font-weight: 100;
}

.label-input {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: 30px;
  width: inherit;
}

.form-label {
  font-size: 0.9rem;
}

.form-input {
  margin-top: 8px;
  padding: 6px 9px;
  border-radius: 5px;
  height: 20px;
  margin-bottom: 25px;
  border: 1px solid grey;
}

.form-btn {
  width: inherit;
  border-radius: 5px;
  background-color: #36a1fb;
  color: white;
  font-weight: 600;
  font-size: 1rem;
  height: 35px;
  border: none;
  margin-top: 14px;
  margin-bottom: 35px;
}

.form-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: inherit;
}

.form-link {
  font-size: 0.8rem;
  color: #36a1fb;
  font-weight: 600;
  text-decoration: none;
}

.form-checkbox {
  font-size: 0.8rem;
}

.login-image {
  height: 500px;
  width: 500px;
}

.one {
  height: 100%;
  width: 320px;
  background-color: #c8def16e;
  position: absolute;
  top: 0;
  z-index: -1;
}

.place1 {
  margin-left: 50px;
}

.place2 {
  margin-left: 390px;
}

.place3 {
  margin-left: 730px;
}

.place4 {
  margin-left: 1070px;
}

.btn {
  height: 38px;
  width: 120px;
  font-size: 1rem;
  font-weight: 600;
  box-shadow: 7px 8px 12px 2px rgb(196 195 195);
  margin: 20px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

@media screen and (max-width: 900px) {
  .login-content {
    grid-gap: 1rem;
    gap: 1rem;
  }
  .form {
    width: 200px;
    padding: 15px 30px 50px;
  }
  .login-image {
    height: 400px;
    width: 400px;
  }
}
@media screen and (max-width: 500px) {
  .login-wrapper {
    align-items: baseline;
  }
  .login-content {
    flex-direction: column-reverse;
  }
  .form {
    width: 75vw;
    border-radius: 1rem;
  }

  .login-image {
    height: 200px;
    width: 300px;
  }
}

.pop-up-container {
    background-color: white;
    border: black double 5px;
    border-radius: var(--border-radius);
    padding: 15px;
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}
.pop-up-header {
    /* margin: 15px; */
    background-color: var(--bg);
    padding-bottom: 10px;
    border-bottom: black double 3px;
    /* padding: 5px; */
    font-size: larger;
    font-weight: bolder;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #36A1FB;
}

.pop-up-content {
    padding: 15px;
    font-size: medium;
    border-bottom: rgb(207, 207, 207) double 3px;
    color: #36A1FB;
}
.pop-up-close {
    color: black;
    cursor: pointer;
    font-weight: 700;
    font-size: 1.1rem;
    padding: 0px 6px;
    border-radius: 50%;
    text-align: center;
    background-color: rgb(209, 209, 209);
}

/* .pop-up-button:hover,
.pop-up-close:hover {
    transition: all 0.3s ease-out;
} */

.pop-up-fields {
    /* border: var(--border); */
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: flex-start;
    /* background-color: gold; */
    /* width: 50%; */
    /* padding: 10px; */
    /* max-width: 300px; */
    padding: 10px 30px 10px 30px;
    

}
.pop-up-field {
    display: flex;
    flex-direction: row;
    /* border: var(--border); */
    align-items: flex-start;
    justify-content: space-evenly;
    padding-left: 5px;
    padding-right: 5px;
    
}

.pop-up-field-label {
    font-family: roboto;
    font-size: medium;
    font-weight: bold;
    /* width: 50%; */

}

.pop-up-field-value {
    border-radius: 2px;
    font-size: medium;
    border: none;
    /* background-color: whitesmoke; */
    color: #36A1FB;
    font-family: roboto;
    /* margin-bottom: 10px; */
    /* z-index: 1000 !important; */
    /* border: var(--border); */
}
.pop-up-actions {
    padding-top: 10px;
    /* margin: 5px; */
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.pop-up-button{
    background-color: #36A1FB;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
}
