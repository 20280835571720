.inner {
  margin: 0px 10px; /*margin:0px 60px;*/
}

.align-btns {
  display: flex;
  align-items: center;
  gap: 30rem;
  margin-top: 30px;
  margin-left: 0px; /*margin:80px;*/
  width: 100%;
}

.align-btn {
  width: 70%;
}

.align-count {
  font-weight: 600;
}

.align-counts {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  width: 30%;
}
