.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.modal-content {
  background: #fff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.modal-heading {
  display: flex;
  justify-content: space-between;
  margin-top: 0;
  margin-bottom: 0;
  gap: 300px;
}

.close-button {
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}

.modal-body {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.amount-input {
  width: 95%;
  padding: 10px;
  border: 1px solid #000;
  border-radius: 5px;
}

.recharge {
  background-color: #36a1fb;
  border: none;
  border-radius: 10px;
  color: white;
  font-size: 0.8rem;
  font-weight: 600;
  cursor: pointer;
  padding: 10px;
  width: 100%;
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

/* For webkit browsers (Chrome, Safari) */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
