.cards {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-rows: auto;
  margin-left: 0px; /*marign-left:100px;*/
  column-gap: 2rem;
}

.card {
  box-shadow: 7px 13px 22px -3px rgb(196 195 195);
  border-radius: 4px;
  padding: 50px;
  cursor: pointer;
}

.cardbordered {
  box-shadow: 7px 13px 22px -3px rgb(196 195 195);
  border-radius: 4px;
  padding: 50px;
  cursor: pointer;
  border: 3px solid #36a1fb;
  border-radius: 7px;
}

.underline {
  text-decoration: none;
  color: black;
  font-size: 1.1rem;
  font-weight: 600;
}
