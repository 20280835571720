.title {
  color: #36a1fb;
  margin: 0;
}

.titlebar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 50px;
  padding-top: 10px;
}

.note {
  font-size: 14px;
  white-space: nowrap;
  color: red;
  width: 50%;
  margin: 0 auto;
  overflow: hidden;
}

.note span {
  display: inline-block;
  padding-right: 10px;
  animation: floatLeft 20s linear infinite;
}

.note:hover span {
  animation-play-state: paused;
}

.login {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.sign-in-name {
  font-weight: 600;
}

.popup-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 35px;
  background-color: white;
  border-radius: 10px;
  position: absolute;
  top: 80px;
  right: 50px;
  z-index: 1;
  box-shadow: 7px 13px 22px 2px rgb(196 195 195);
}

.disable-popup {
  display: none;
}
.user-detail {
  display: flex;
  gap: 20px;
}
.pop-icon {
  height: 80px;
  width: 80px;
}
.email {
  margin-left: 50px;
}
.i-holder {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  gap: 10px;
}
.i-label {
  gap: 1rem;
  display: flex;
  justify-content: space-between;
}
.btn-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}
.popup-btn {
  padding: 10px;
  background-color: #36a1fb;
  border: none;
  border-radius: 10px;
  color: white;
  font-size: 0.8rem;
  font-weight: 600;
  cursor: pointer;
}
.btn-holder {
  display: flex;
  height: max-content;
  gap: 25px;
}
.radio-holder {
  display: flex;
  gap: 5px;
  align-self: center;
}
.flex-max {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.flex-justify {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}
.qr-holder {
  display: flex;
  flex-direction: column;
  align-self: center;
  text-align: center;
  font-weight: 600;
  align-items: center;
}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropbtn {
  background-color: gray;
  padding: 10px;
  border: none;
  border-radius: 10px;
  color: white;
  font-size: 0.8rem;
  font-weight: 600;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 45px;
}

.dropdown-content button {
  color: black;
  padding: 10px 15px;
  text-decoration: none;
  display: block;
  cursor: pointer;
  width: 100%;
}

.dropdown-content button:hover {
  background-color: #ddd;
}

.dropdown-content {
  display: block;
}

.selected-option {
  margin-top: 10px;
}

.fastag-popup {
  /* width: fit-content; */
  width: 880px;
  position: absolute;
  right: auto;
}

.tableContainer {
  max-height: 400;
  overflow-wrap: "auto";
}

.flex-justify-fastag {
  display: flex;
  gap: 1rem;
}

@keyframes floatLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
